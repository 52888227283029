<template>
  <div class="addGoods bg-f p-16">
    <search-group
      :searchs="searchList"
      :formInitData="{}"
      @search="searchClick"
    ></search-group>
    <div class="m-20">
      <el-button
        type="primary"
        @click.stop="setShowDialog(true)"
      >
        自动上品
      </el-button>
    </div>
    <div>
      <el-table
        ref="table"
        :data="data"
        class="my-good-table"
        v-loading="loading"
      >
        <el-table-column
          prop="id"
          width="80"
          label="序号"
        ></el-table-column>
        <el-table-column
          prop="platform"
          width="100"
          label="平台"
        ></el-table-column>
        <el-table-column
          min-width="260"
          label="广告账户"
        >
          <template slot-scope="scope">
            <div
              @click="toAdManagement(scope.row)"
              :class="['cursor-pointer', scope.row.can_jump ? 'text-[#409EFF]' : '']"
            >
              <span>{{ scope.row.accountName }}</span>
              <p>{{ scope.row.accountId }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="productName"
          label="产品名称"
          min-width="260"
        ></el-table-column>
        <el-table-column
          prop="saleId"
          label="商品id"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="zipName"
          label="压缩包名称"
          width="320"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="180"
        >
          <div slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.failReason"
              placement="top-start"
              :disabled="scope.row.status != -1"
            >
              <div class="dp-flex align-item-c">
                <div
                  class="dot"
                  :class="{ green: scope.row.status == 10, red: scope.row.status == -1 }"
                ></div>
                {{ statusEume[scope.row.status]
                }}<i
                  v-if="scope.row.status == -1"
                  class="el-icon-warning ml-5"
                ></i>
              </div>
            </el-tooltip>
          </div>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="上传时间"
          width="180"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          width="180"
        >
          <div slot-scope="scope">
            <!-- <el-popconfirm
              title="删除后，数据将消息，确认删除吗？"
              @confirm="deleteItem(scope.row.id)"
              icon="el-icon-info"
              class="popconfirm"
            >
              <el-button
                slot="reference"
                type="text"
                class="mx-10"
                >删除</el-button
              >
            </el-popconfirm> -->

            <el-button
              type="text"
              @click="lookLog(scope.row.id)"
              v-if="scope.row.status != 0"
            >
              {{ scope.row.status != -10 ? '执行日志' : '--' }}
            </el-button>
            <el-button
              v-if="
                scope.row.status === 1 || scope.row.status === 3 || scope.row.status === 10 || scope.row.status === -2
              "
              type="text"
              @click="lookDraft(scope.row.id)"
            >
              草稿详情
            </el-button>
            <el-button
              type="text"
              v-if="scope.row.can_edit"
              @click="edit(scope.row.id)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              v-if="scope.row.status === 0"
              @click="cancelLog(scope.row.id)"
            >
              取消
            </el-button>
          </div>
        </el-table-column>
      </el-table>
      <div
        class="page"
        style="margin-top: 10px; text-align: right"
      >
        <el-pagination
          :current-page="pagination.page_num"
          :page-sizes="[1, 10, 20, 30, 40, 50, 80, 100]"
          :page-size="pagination.page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <autoAddGoods
      :dialogVisible="showDialog"
      @setShowDialog="setShowDialog"
      @getList="getList"
      :id="editId"
    ></autoAddGoods>

    <!-- 执行日志 -->
    <log-droap
      :visible.sync="showLog"
      :logs="logs"
      @close="setShowLog"
    ></log-droap>
    <DraftDetail
      :visible.sync="showDraft"
      :list="draftList"
    ></DraftDetail>
  </div>
</template>
<script>
import autoAddGoods from './components/autoAddGoods.vue';
import searchGroup from '@/components/common/SearchGroup.vue';

import logDroap from './components/LogDrawer.vue';
import DraftDetail from './components/DraftDetail.vue';
import { findByCondition, queryByRecordId, queryDraftCampaignInfo, cancelTask } from '@/api/addGoods';
const STATUS = {
  '-2': '广告创建失败',
  '-1': '失败',
  0: '未处理',
  1: '广告上传中',
  2: '处理中',
  3: '广告上传部分成功',
  10: '广告上传成功',
  '-10': '已取消',
};
const searchList = [
  {
    type: 'select',
    prop: 'platform',
    formOptions: {
      placeholder: '平台',
    },
    options: [
      {
        label: 'Facebook',
        value: 'facebook',
      },
      {
        label: 'Snapchat',
        value: 'snapchat',
      },
      {
        label: 'Tiktok',
        value: 'tiktok',
        disabled: true,
      },
      {
        label: 'Google',
        value: 'google',
        disabled: true,
      },
      {
        label: 'Line',
        value: 'line',
        disabled: true,
      },
    ],
    optionConfig: {
      disabled(option) {
        if (option.disabled) {
          return true;
        }
      },
    },
  },
  {
    type: 'input',
    prop: 'productIds',
    formOptions: {
      placeholder: '产品ID,多个逗号分割',
    },
  },
  {
    type: 'input',
    prop: 'zipName',
    formOptions: {
      placeholder: '压缩包名称',
    },
  },
  {
    type: 'select',
    prop: 'status',
    formOptions: {
      placeholder: '状态',
    },
    options: [
      {
        label: '广告创建失败',
        value: -2,
      },
      {
        label: '失败',
        value: -1,
      },
      {
        label: '未处理',
        value: 0,
      },
      {
        label: '广告上传中',
        value: 1,
      },
      {
        label: '处理中',
        value: 2,
      },
      {
        label: '广告上传部分成功',
        value: 3,
      },
      {
        label: '广告上传成功',
        value: 10,
      },
      {
        label: '已取消',
        value: -10,
      },
    ],
  },
];
export default {
  components: {
    autoAddGoods,
    searchGroup,
    logDroap,
    DraftDetail,
  },
  data() {
    return {
      searchList,
      statusEume: STATUS,
      data: [],
      searchData: {},
      pagination: {
        page_size: 10,
        total: 0,
        page_num: 1,
      },
      loading: false,
      showDialog: false, //自动上品
      logs: [], // 执行日志
      showLog: false, // 显示执行日志
      showDraft: false,
      draftList: [],
      loadingDraft: false,
      editId: null,
    };
  },
  watch: {
    data() {
      this.$refs.table.doLayout();
    },
  },
  created() {
    this.getList();
  },
  computed: {},
  methods: {
    // 获取表格数据
    getList() {
      console.log(this.searchData, 'this.searchData ');
      const { productIds = '', zipName, status, platform } = this.searchData;
      const { page_num, page_size } = this.pagination;
      const params = {
        productIds: productIds ? productIds.split(',') : null,
        zipName,
        status,
        platform,
        page_size,
        page_num: page_num,
      };
      this.loading = true;
      findByCondition(params)
        .then((res) => {
          if (res.code === 0) {
            this.data = res.data.content;

            this.$set(this.pagination, 'total', res.data.totalElements);
          }
        })
        .finally(() => {
          this.$nextTick(() => {
            this.$refs.table.doLayout();
          });
          this.loading = false;
        });
    },
    searchClick(data) {
      console.log(data);
      const regex = /^[0-9,]+$/;
      if (data.productIds && !regex.test(data.productIds)) {
        this.$message.error('请输入正确的产品ID');
        return;
      }
      this.searchData = data;
      console.log(this.searchData, 'this.searchData ');
      this.getList();
    },
    // 切换页数
    handleCurrentChange(page_num) {
      const pagination = {
        ...this.pagination,
        page_num,
      };
      this.pagination = { ...pagination };
      this.getList();
    },
    // 切换每页数据条数
    handleSizeChange(page_size) {
      const pagination = {
        ...this.pagination,
        page_num: 1,
        page_size,
      };
      this.pagination = { ...pagination };
      this.getList();
    },
    setShowDialog(bool = false) {
      this.editId = null;
      this.showDialog = bool;
    },
    getLogs(id) {
      this.logs = [];
    },
    setShowLog(bool = false) {
      this.showLog = bool;
    },
    // 查看日志
    lookLog(recordId) {
      let params = {
        recordId,
      };
      queryByRecordId(params).then((res) => {
        if (res.code == 0) {
          this.logs = res.data;
          this.setShowLog(true);
        }
      });
    },
    //取消执行
    cancelLog(recordId) {
      this.$confirm('取消后，将不在自动创建创建，确认取消吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let params = {
            recordId,
          };
          cancelTask(params).then((res) => {
            this.getList();
            if (res.code == 0) {
              this.$message({
                type: 'success',
                message: '取消成功!',
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 查看草稿
    lookDraft(recordId) {
      this.showDraft = true;
      this.$nextTick(() => {
        this.$showLoading();
        queryDraftCampaignInfo({ recordId }).then((res) => {
          this.$hideLoading();
          if (res.code == 0 && res.data && res.data.length > 0) {
            this.draftList = res.data;
          }
        });
      });
    },
    // 点击广告账户跳转广告管理页面
    toAdManagement(row) {
      if (!row.can_jump) {
        this.$message({
          type: 'warning',
          message: '广告未上传成功，暂不支持跳转',
        });
        return;
      }
      if (!row.thirdId) {
        this.$message({
          type: 'warning',
          message: '凭证id为空，暂不支持跳转',
        });
        return;
      }
      const baseUrl = process.env.VUE_APP_FB;
      let url = `${baseUrl}ad/adManagement?account_id=${row.accountId}&third_user_id=${row.thirdId}&campaign_id=${row.saleId}`;
      window.open(url);
    },
    // 编辑
    edit(id) {
      this.showDialog = true;
      this.editId = id;
    },
  },
};
</script>
<style lang="scss">
.my-good-table {
  overflow-x: auto !important;
  .el-table__body-wrapper {
    height: calc(100vh - 389px);
    overflow: auto !important;
  }
}
.el-table__body-wrapper {
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>
<style lang="scss" scoped>
.addGoods {
  height: calc(100vh - 50px);
}

.dot {
  width: 5px;
  height: 5px;
  margin-right: 5px;
  border-radius: 50%;
  background: #e6a23c;
}
.green {
  background: #66cc66;
}
.red {
  background: #cc3300;
}
</style>

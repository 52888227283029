<template>
  <div class="interesting">
    <el-popover
      placement="bottom"
      width="600"
      v-model="visible"
      style="max-height: 300px"
      class="locationPopover"
    >
      <div class="treeBox">
        <el-tree
          :data="list"
          show-checkbox
          :props="{ label: params.name, children: params.children }"
          :node-key="params.id"
          :filter-node-method="filterNode"
          @check="selectChange"
          @node-click="clickNode"
          ref="tree"
        ></el-tree>
      </div>
      <el-form-item
        :label="label"
        :prop="params.prop"
        slot="reference"
      >
        <div
          class="locations"
          @click="visible = !visible"
          v-if="checkedList.length"
        >
          <el-tag
            size="mini"
            type="info"
            v-for="tag in checkedList"
            :key="tag.name"
            closable
            @close="deleLocations(tag)"
          >
            {{ tag.name }}
          </el-tag>
        </div>
        <el-button
          class="ml-10"
          type="text"
          v-if="checkedList.length"
          @click="clearLocation"
          >清除所有</el-button
        >
        <el-input
          class="input"
          clearable
          v-model="indCodeName"
          @input="filterText"
          :placeholder="placeholder"
        ></el-input>
        <region-group
          :list="listDefault"
          :countryCodes="countryCodesList"
          @set-regions="setLocations"
          :isC="isC"
          savedType="GEO_REGION"
          :geos="[]"
          keys="id"
          type="key"
          class="select_tags"
          ref="regionGroup"
        ></region-group>
      </el-form-item>
    </el-popover>
    <!-- <el-popover placement="bottom" style="width: 33.33%" width="300px" v-model="mainTreeFlag"> -->
  </div>
</template>

<script>
import _ from 'lodash';
import regionGroup from './regionGroup.vue';

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    params: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
    place: {
      type: String,
      default: '',
    },
    keys: {
      type: String,
      default: '',
    },
    listDefault: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    countryCodes: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    regionGroup,
  },
  data() {
    let checkSelect = (rule, value, callback) => {
      if (this.required) {
        if (this.checkedList && !this.checkedList.length) {
          return callback(new Error('请选择兴趣词'));
        }
      }
    };
    return {
      mainTreeFlag: null,
      eleIndList: [],
      ruleForm: {},
      indTreeFlag: false,
      interest: [],
      indCodeName: '',
      checkedNodes: [],
      isC: false,
      countryCodesList: this.countryCodes,
      visible: false,
    };
  },
  computed: {
    accountId() {
      return this.$store.getters.accountId;
    },
    ttUserId: {
      get() {
        return this.$store.state.num.selectedNum;
      },
    },
    list() {
      return this.addParentId(this.data, 0);
    },
    checkedList: {
      get() {
        let selectList = this.checkedNodes.map((v) => {
          return {
            id: v[this.params.id],
            name: v[this.params.name],
            parentId: v.parentId,
            children: v[this.params.children],
          };
        });
        let list = selectList.filter((v) => selectList.every((a) => a[this.params.id] != v.parentId));
        return list;
      },
      set() {},
    },
    placeholder() {
      if (this.keys == 'location') {
        return this.place;
      } else if (this.keys == 'interest') {
        return this.checkedList.length ? `已选择${this.checkedList.length}个兴趣词` : this.place;
      } else if (this.keys == 'video') {
        return this.checkedList.length ? `已选择${this.checkedList.length}个分类` : this.place;
      } else if (this.keys == 'model') {
        return this.checkedList.length ? `已选择${this.checkedList.length}种设备机型` : this.place;
      }
    },
  },
  methods: {
    //当地区变化时查询标签列表
    changeInterestLabel() {
      this.$refs.regionGroup.getList();
    },
    setLocations(v) {
      console.log(v, '常用选项', JSON.parse(v));
      // this.form.interests = JSON.parse(v);
      this.$emit('setList', JSON.parse(v));
    },
    filterText: _.debounce(function (val) {
      this.$refs.tree.filter(val);
    }, 300),
    filterNode(value, data) {
      if (!value) return true;
      //统一成小写 忽略大小写
      const pathList = data.path.split('/');
      pathList.shift();
      return data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      // return this.checkContain(data.name.toLowerCase(), value.toLowerCase());
    },
    checkContain(a, b) {
      let aaa = [];
      for (const letter of b) {
        if (a.includes(letter)) {
          aaa.push(true);
        } else {
          aaa.push(false);
        }
      }
      return !aaa.includes(false);
    },
    /**
     * @brief        :实现模糊匹配
     * @Description  :本例是首字母相同，并且包含搜索的所有关键字，例如输入“计科”，返回计算机科学与技术。
     * @param         {*} i:计算机科学与技术 需要被匹配的字符串
     * @param         {*} j:计科 拿着j去和别的字符串匹配
     * @return        {*} true：匹配 false：不匹配
     */
    checkStrContain(i, j) {
      if (!i || !j) {
        return false;
      }
      if (i.charAt(0) != j.charAt(0)) {
        return false;
      }
      i = i.substr(1, i.length - 1);
      j = j.substr(1, j.length - 1);
      var a;
      var b;
      if (i.length > j.length) {
        a = i;
        b = j;
      } else {
        a = j;
        b = i;
      }
      var count = 0;
      for (var bi = 0; bi < b.length; bi++) {
        var bArr = b.split('');
        if (a.indexOf(bArr[bi]) != -1) {
          count++;
        } else {
          break;
        }
      }
      if (b.length == count) {
        return true;
      } else {
        return false;
      }
    },
    selectChange(a, checkedNodes) {
      // 重新设置选中，不显示的数据全选的时候设置不选中
      let checkedKeys = this.$refs.tree.getCheckedKeys();
      checkedKeys.forEach((i,n) => {
        var node = this.$refs.tree.getNode(i);
        // console.log(node);
        if(!node.visible && node.checked){
          this.$refs.tree.setChecked(i,false)
        }
      });
      let currentCheckedNodes = this.$refs.tree.getCheckedNodes();
      this.checkedNodes = JSON.parse(JSON.stringify(currentCheckedNodes));
      // console.log(this.checkedNodes);
      this.checkChange();
    },
    // 清除所有
    clearLocation() {
      this.$refs.tree.setCheckedNodes([]);
      this.checkedNodes = [];
      this.checkChange(true);
    },
    // 删除单个
    deleLocations(tag) {
      console.log(tag, 'tag');
      this.checkedNodes = this.checkedNodes.filter((v) => v[this.params.id] !== tag[this.params.id]);
      this.$refs.tree.setChecked(tag[this.params.id], false, true);
      this.checkedNodes = this.$refs.tree.getCheckedNodes();
      this.checkChange(!this.checkedNodes.length);
    },
    // 点击节点
    clickNode(data, node) {
      let list = JSON.parse(JSON.stringify(this.checkedNodes));
      if (this.checkedNodes.filter((v) => v[this.params.id] == data[this.params.id]).length) {
        this.checkedNodes = this.checkedNodes.filter((v) => v[this.params.id] != data[this.params.id]);
      } else {
        this.checkedNodes.push(data);
      }
      this.$refs.tree.setCheckedKeys(this.checkedNodes.map((v) => v[this.params.id]));
      this.checkChange();
    },
    checkChange(val) {
      let selectList = this.checkedNodes.map((v) => {
        return {
          id: v[this.params.id] + '',
          name: v[this.params.name],
          parentId: v.parentId,
          children: v[this.params.children],
          code: v.code,
          region_code: v.region_code,
          type: v.type || null,
        };
      });
      let list = selectList.filter((v) => selectList.every((a) => a[this.params.id] != v.parentId));
      if (list.length == 0) this.$refs.tree.setCheckedKeys([]);
      console.log(selectList, list);
      console.log(val, 'val');
      if (val) this.isC = true;
      else this.isC = false;
      this.$emit(
        'setList',
        list.map((v) => v.id),
      );
    },
    getLocations() {
      locations().then((res) => {
        // this.interest = res.data;
      });
    },
    addParentId(list, id, code) {
      list.map((v) => {
        v.parentId = id;
        v.code = code || v.code;
        if (v[this.params.children]) {
          this.addParentId(v[this.params.children], v[this.params.id], v.code);
        }
      });
      return list;
    },
  },
  watch: {
    countryCodes: {
      handler(newV) {
        this.countryCodesList = newV;
      },
      deep: true,
      immediate: true,
    },
    listDefault: {
      deep: true,
      immediate: true,
      handler(newV, oldV) {
        if (this.listDefault && this.listDefault.length && this.data.length) {
          let list = this.listDefault.length
            ? typeof this.listDefault[0] == 'string' || typeof this.listDefault[0] == 'number'
              ? this.listDefault
              : this.listDefault.map((v) => v[this.params.id])
            : [];
          this.$nextTick(() => {
            this.$refs.tree.setCheckedKeys([...list]);
            this.$nextTick(() => {
              this.checkedNodes = JSON.parse(JSON.stringify(this.$refs.tree.getCheckedNodes()));
            });
          });
          this.$forceUpdate();
        } else {
          console.log('清空了-----');
          this.$nextTick(() => {
            this.checkedNodes = [];
            if (this.$refs.tree) this.$refs.tree.setCheckedNodes([]);
          });
        }
      },
    },
  },
  mounted() {
    console.log('mountesd');
    // let list = JSON.parse(JSON.stringify(interest.data.ad_interest_list));
    // this.interest = this.addParentId(list,0);
    // this.getLocations();
  },
};
</script>
<style lang="scss">
.interesting {
  .el-form-item__content {
    display: flex;
    flex-wrap: wrap;
    line-height: 0;
  }
  .el-form-item__label {
    width: 100px;
  }
  .input {
    width: calc(100% - 200px);
    margin-right: 10px;
    // margin-left: 100px;
  }
  .select_tags {
    width: 180px;
  }
}
.el-popover {
  max-height: 400px;
  overflow-y: scroll;
}
.locations {
  width: 89%;
  margin-bottom: 11px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
  // .el-button {
  //   position: absolute;
  //   top: 10px;
  //   right: -60px;
  // }
  .el-tag {
    margin: 5px;
  }
}
</style>
